.r1yuq4tr{background-color:#eee;padding-top:30px;}
.s1rvwtwf.s1rvwtwf.s1rvwtwf.s1rvwtwf.s1rvwtwf.s1rvwtwf{margin:30px;margin-top:0px;width:initial;}
.aa5x3fh{margin-top:30px;margin-bottom:0px;}
.t1lp450w.t1lp450w.t1lp450w{background:rgb(255,255,255);margin:0px;}
.i4we885{padding:0px 15px;padding-bottom:15px;}
.lhx3dfi{text-align:left;}
.l63r0t7:before{content:"-";padding:3px;}
.i1wyuzoj{display:inline-block;}
.a1coh9os{width:71px;}
.acer9m6{width:445px;}
