.l1kxev90:before{content:"-";padding:3px;}
.t7z2sp9{padding:0px 15px;}.t7z2sp9 span{padding-left:10px;vertical-align:-0.25em;}
.t1vl77fd.t1vl77fd.t1vl77fd.t1vl77fd.t1vl77fd.t1vl77fd.t1vl77fd{margin-top:0.5rem;margin-bottom:0.5rem;}
.istgzev.istgzev.istgzev.istgzev{padding:0px 15px;}
.w1gna08m{color:white;}
.hfbdyh4{color:#f77979;}
.pufv4hh.pufv4hh.pufv4hh{background-color:transparent;}.pufv4hh.pufv4hh.pufv4hh:hover{background-color:rgb(238,238,238);}
.e1hu4o76{width:71px;}
.e18n974b{width:320px;}
.e792fxv{width:100px;margin:3px;}
.p12obfkk{width:150px;}
.pm4wv5g.pm4wv5g.pm4wv5g.pm4wv5g.pm4wv5g.pm4wv5g{box-shadow:none;}.pm4wv5g.pm4wv5g.pm4wv5g.pm4wv5g.pm4wv5g.pm4wv5g:focus{box-shadow:none;}.pm4wv5g.pm4wv5g.pm4wv5g.pm4wv5g.pm4wv5g.pm4wv5g:hover{background-color:rgb(238,238,238);box-shadow:none;}
.d10q163c.d10q163c.d10q163c{font-size:1.07142857em;}
.i1ggqihm{display:inline-block;}
