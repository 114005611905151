.aw-header {
  background: rgb(111, 191, 213);
  height: 50px;
}
.aw-header-inner {
  display: flex;
}
.aw-header-img {
  height: 30px;
  width: 30px;
}
.aw-header-item {
  align-items: center;
  display: flex;
  height: 48px;
  margin-right: 10px;
  margin-right: 16px;
}
.aw-header-item-full {
  flex: auto;
}
.aw-header-item-logo {
  margin-right: 7px;
}
.aw-product-name {
  align-items: center;
  color: #ffffff;
  display: flex;
  font-size: 20px;
  font-weight: 680;
  height: 49px;
  margin-right: 30px;
}
.aw-product-name:hover {
  color: #ffffff;
}
.aw-menu-button {
  align-items: center;
  color: #ffffff;
  display: flex;
  font-size: 16px;
  font-weight: 550;
  height: 48px;
  margin-right: 10px;
  padding: 10px;
  text-decoration: none;
}
.aw-menu-button:hover {
  color: #ffffff;
}
.aw-current-content {
  background-color: rgb(91, 166, 188);
}
.aw-menu-button:hover {
  background-color: rgb(101, 178, 200);
}

* header div.aw-header-inner img {
  width: 30px;
  height: 30px;
}

.aw-header-dropdown {
  border-color: #fff;
}
.aw-header-dropdown > div.ui.selection.dropdown {
  background-color: transparent;
  border-color: #fff;
  color: #ffffff;
}
.aw-header-dropdown > div.ui.selection.dropdown:hover {
  border-color: #fff;
}
.aw-header-dropdown > div.ui.selection.dropdown > div.text:not(.default) {
  border-color: #fff;
  color: #ffffff;
}
.aw-header-item > div.aw-logout {
  cursor: pointer;
}