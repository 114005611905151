.okg4fng{border-bottom:solid 0.8em lightgray;}.okg4fng.aw-top-row{border-top:solid 0.8em lightgray;}
.on1j4h0{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.t17p92s1{font-weight:bold;}
.n33cw9z.n33cw9z.n33cw9z.n33cw9z{padding-top:0px;padding-bottom:0px;}
.e6zt2sp{border-left:solid 1.5px lightgray;}.e6zt2sp.aw-right-edge{border-right:solid 1.5px lightgray;}
.e1hotdmh{padding-top:1em;padding-bottom:1em;font-weight:bold;}
.t1c6sou0{background-color:#f6ed00;}
.i13edvce{font-weight:bold;}.i13edvce.aw-non-working{font-size:x-small;color:palevioletred;}.i13edvce.aw-working{font-size:x-small;color:darkgray;}
.i11spaby{font-weight:bold;}.i11spaby.aw-non-working{font-size:normal;color:palevioletred;}.i11spaby.aw-working{font-size:normal;color:darkgray;}
.d17qw1t{border-left:solid 1.5px lightgray;padding-left:0px;padding-right:0px;}
.iqqb5jj{border-bottom:solid 1.5px lightgray;}.iqqb5jj.aw-right-edge{border-right:solid 1.5px lightgray;}
.po0jlmp.po0jlmp.po0jlmp{background-color:transparent;}.po0jlmp:hover{background-color:rgb(238,238,238);}
.e1zlrmi.e1zlrmi.e1zlrmi > input{text-align:center;}
.chutcvq.chutcvq.chutcvq > input{text-align:right;}
.pdofrp3{width:100px;}
.p7arsxh.p7arsxh.p7arsxh.p7arsxh.p7arsxh{box-shadow:none;}.p7arsxh.p7arsxh.p7arsxh.p7arsxh.p7arsxh:focus{box-shadow:none;}.p7arsxh.p7arsxh.p7arsxh.p7arsxh.p7arsxh:hover{background-color:rgb(238,238,238);box-shadow:none;}
