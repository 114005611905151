.aw-modal-header {
  padding: 1rem 1rem 0 1rem;
}
.aw-modal-content {
  flex: 1;
  padding: 0 1rem;
}
.aw-modal-footer {
  display: flex;
  justify-content: right;
  background: #f9fafb;
  padding: 1rem;
  border-top: 1px solid rgba(34,36,38,.15);
}
.aw-modal-footer > .ui.button {
  margin-left: .75em;
}
.aw-modal-error {
  color: red;
  white-space: pre-wrap;
}

.aw-datepicker {
  border: 1px solid #aeaeae;
  border-radius: 4px;
  box-shadow: inset 0 2px 2px #e9e9e9;
  padding: 6px 10px 5px;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #2a87d030;
  color: #000;
}
