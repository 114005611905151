.aw-unhandled-error-modal-content {
  flex: 1;
  padding: 0 1rem;
}
.aw-unhandled-error-modal-footer {
  display: flex;
  justify-content: center;
  background: #f9fafb;
  padding: 1rem;
  border-top: 1px solid rgba(34,36,38,.15);
}
.aw-modal-error-message {
  justify-content: center;
  resize: none;
  padding: 5px;
  width: 100%;
  height: 160px;
}
