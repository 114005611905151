.react-calendar-timeline .rct-header-root {
  background-color: transparent;
}
.react-calendar-timeline .rct-dateHeader-primary {
  background-color: #fdeff2;
  color: initial;
}
.react-calendar-timeline .rct-dateHeader {
  background-color: #fdeff2;
}
.react-calendar-timeline .rct-dateHeader.aw-expected,
.react-calendar-timeline .rct-dateHeader.aw-remain {
  background-color: transparent;
}
.react-calendar-timeline .rct-dateHeader.aw-past {
  background-color: #ddf;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl.af-past {
  background-color: #ddfa;
}
.react-calendar-timeline .rct-dateHeader.aw-day.aw-today {
  font-weight: bold;
}
.react-calendar-timeline .rct-dateHeader.aw-day.aw-red-color {
  color: orangered;
}
.react-calendar-timeline .rct-dateHeader.aw-day .aw-day-of-week {
  font-size: 11px;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
  background-color: transparent;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  background-color: transparent;
}

.aw-sidebar-header {
  height: 30px;
}
.aw-sidebar-header-text {
  height: 30px;
  text-align: right;
  padding-right: 5px;
  position: relative;
  top: 6px;
}
.aw-sidebar-header-row {
  display: flex;
  height: 30px;
  border-top: 1px solid gray;
}
.aw-group-mode-header {
  flex-grow: 1;
  padding-top: 4px;
  padding-left: 10px;
}
.aw-group-remain-header {
  width: 75px;
  border-left: 1px solid gray;
  padding-top: 4px;
  text-align: center;
}
.aw-group-total-header {
  width: 79px;
  border-left: 1px solid gray;
  padding-top: 4px;
  text-align: center;
}
.aw-group-row {
  display: flex;
}
.aw-group-title-container {
  display: flex;
  flex-grow: 1;
}
.aw-group-title {
  flex-grow: 1;
}
.aw-group-job-active-icon {
  padding: 0;
  margin: 0;
}
.aw-pointer {
  cursor: pointer;
}
.aw-group-title-expand-icon {
  display: inline-block;
  width: 20px;
}
.aw-group-remain {
  width: 75px;
  border-left: 1px solid gray;
  padding-right: 5px;
  text-align: right;
}
.aw-group-total {
  width: 75px;
  border-left: 1px solid gray;
  padding-right: 5px;
  text-align: right;
}
.external.alternate.icon {
  margin: 3px;
}
.folder.icon {
  padding: 0;
  margin: 0;
}