.aw-reset-password-form {
  max-width: 600px;
  margin: auto;
}

.aw-reset-password-error {
  margin-top: 10px;
  text-align: center;
}

.aw-reset-password-success {
  margin-top: 10px;
  text-align: center;
}
