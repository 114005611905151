.aw-sign-up-form {
  width: 300px;
  margin: auto;
}

.aw-sign-up-error {
  margin-top: 10px;
  text-align: center;
}

.aw-sign-up-success {
  margin-top: 10px;
  text-align: center;
}
